import React from 'react'
import { Link, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

//fontawsome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

//components
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import StayLoop from "../../components/Home/stayloop";
import MoreArticles from "../../components/Home/morearticles";

const BlogSingle = (props) => {
  const { blog: post } = props.data
  const SEOdata = props.data.awardpagedata.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpagedata.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpagedata.edges[0].node.frontmatter?.Hreflang
  const { edges: morepost } = props.data.moreArticles
  return (
    <Layout>
     <Seo
        title={SEOdata ? SEOdata.seo_title : "How IoT Technology is a Blessing for the Businesses of Today"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />


      {/* blog description section */}

      <section className="mt-60 lg:mt-20 pt-6-6 pb-35 lg:pt-51 lg:pb-59">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="hidden xl:block w-full lg:w-1/6"></div>
            <div className="w-full lg:w-4/5 xl:w-2/3">
              <h2 className="mb-3-3 lg:mb-6-3 lg:mb-1-3 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-term-primary">{post.frontmatter.title}</h2>
              <div className="blog-inner--cnt text-p4 lg:text-p2 font-worksans-normal text-term-primary"><h2>How IoT Technology is a Blessing for the Businesses of Today?</h2>
<p>We love the connected world ever since we have experienced what goodies Internet of Things technology has given to us.</p>
<p>From transportation to manufacturing to healthcare, IoT has proven itself in every industry thereby playing a huge role in transforming the global economy. IoT refers to the interconnection of devices and gadgets to collect, transmit and store data. With the cost-reducing every time, businesses can leverage IoT at a very affordable price.</p>
<p>Before we jump into the IoT applications for various industries, let us have a brief overview of the benefits a business of any size can avail of by implementing IoT technology.</p>
<h2>Advantages of Internet of Things (IoT) Technology Implementation</h2>
<h5>Monitor Data</h5>
<p>Undoubtedly one of the most influential benefits of IoT applications is fetching deep data for real-time monitoring. The primary and main advantage of IoT is monitoring. Be it the production of the precise quantity in a manufacturing unit, or the quality of the air that is flowing your home, or crops or livestock monitoring in a farm, custom IoT solutions leveraging the right technology and IoT sensors can provide data it was impossible to collect before.</p>
<h5>Ease of Access</h5>
<p>As we speak, well-known fleet management and logistic businesses around the world are reaping the rewards of implementing IoT-based solutions to monitor the operations and keep track of drivers, vehicles, and order deliveries from a remote centralized system, everything in real-time with alerts too.</p>
<p>IoT technology empowers the admins or the owners to access the remote real-time operations and make decisions for better productivity. All you need to do is contact us to design a bespoke IoT system that works over the internet. With the Internet of Things, any information that you need is easily accessible, to make an effective business analysis report. It is only a <a href="/get-in-touch">click away</a>.</p>
<h5>Better Overall Productivity with Reduced Monotonous Efforts</h5>
<p>Mundane tasks in business processes can be easily automated with the help of IoT technology. This is one of the top benefits that IoT has to offer. It allows humans to get occupied with even more complex or challenging or productive tasks that require human intelligence based on the skills of the resources.</p>
<p>This way, the number of workers can be minimized, which results in reduced costs of business operation.</p>
<p>IoT also helps in gaining speed in the processes with pinpoint accuracy.</p>
<h5>Adapting to New Standards</h5>
<p>As IoT is an ever-changing topic, its changes are minimal compared to the other techs of the high-tech world. Without IoT, it would be complicated for us to keep track of all the latest things.</p>
<h5>Overall Cost Reduction</h5>
<p>The more businesses use IoT devices to streamline operations and increase profitability, the more IoT-based solutions will be tailored to help those businesses succeed.</p>
<p>The biggest problem for, let’s say, fleet companies is the unmonitored maintenance cost. They simply had no track of costs for the vehicles in the fleet. Maintenance costs can be positively impacted when IoT devices are used with sensors to keep business equipment running at peak efficiency. Real-time alerts can also be received with IoT sensors when a mechanical failure occurs so that it can be repaired before the condition goes from bad to
worse.</p>
<p>This can be a GREAT advantage for the businesses in manufacturing, logistics, food delivery, fleet management industries.</p>
<h5>Automation and Control</h5>
<p>These two phrases define the whole concept of implementing a successful IoT solution in place in the true sense. Automation is an idea of IoT that involves direct communication between separate devices, apparatuses, and other hardware without human interference. Control relates directly with connectivity, it means enhanced communication between the devices in a network that allows users to have more traction of what is happening by giving quality data as an output with the use of IoT sensors. Without human interference, the machines are communicating with each other providing faster and timely output.</p>
<h5>Enhances Brand Image &amp; Reputation</h5>
<p>Today, it is even more likely that any business that adopts hi-tech solutions using cutting-edge technology like IoT, gain massive recognition in the market. It immediately shows the long-term vision of the company and leaves a positive impression on the consumers, the investors, and the company stakeholders. Also, creative and intelligent candidates will look for every opportunity to work in a company where innovation is encouraged to become better than yesterday.</p>
<h2>Applications of IoT in various Industries</h2>
<h5>Smart Automobile</h5>
<p>Today’s cars are already connected and have been connected for some time since they can link to smartphones, offer emergency roadside assistance, register real-time traffic alerts, etc., but this evolution is about to change. Researchers have always wondered about how self-driven cars would function in the future and the force behind this is the Internet of Things. IoT will transform the automobile industry and IoT will provide a big boost to IoT.</p>
<h5>Smart Healthcare</h5>
<p>IoT in the healthcare industry is a big boon to society. It has turned data into action, promotes preventive measures, improves patient’s health by providing accurate details, and enhances patient satisfaction and engagement. In healthcare, IoT will solve the problems by helping to optimize the way things are done. Connected technology will see fewer missed appointments, improved ways to care plans, and improved consequences such as reduced inpatient admissions.</p>
<h5>Home Automation</h5>
<p>With a smart home, all your worries will shoo away with a quick glance at your smartphone or tablet. The devices and appliances in your home can be connected so they can communicate with each other and with you. Any electric device can be put on the home network at your own command. That command can be managed by voice, remote control, tablet, or smartphone, the home reacts. Most applications relate to lighting, home security, home theater and entertainment, and thermostat regulation.</p>
<h2>Final Say</h2>
<p>Even at the current state, the capabilities of IoT solutions are impressive, and their advantages are tempting. However, it is important to realize that the integration of IoT in an enterprise requires a lot of effort and skills in order to achieve those benefits without suffering from their drawbacks.</p>
<p>That’s why the most reasonable course of action is to contact the IoT engineers from the best IoT applications development company that provides 360-degree development services, from consultation to development, implementation, and beyond regarding post-launch maintenance and support.</p></div>
            </div>
            <div className="w-full lg:w-1/6">
              <div className="w-full lg:w-3/4 text-left lg:text-right flex flex-wrap lg:flex-col justify-between">
                <div className="lg:mb-39">
                  <h5 className="lg:mb-1-1 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                    {post.frontmatter.blog_category}
                  </h5>
                  <p className="text-term-primary font-worksans-normal text-p4 lg:text-p3">
                    {post.frontmatter.date}
                  </p>
                </div>
                <div className="flex items-center lg:items-end lg:flex-col ">
                  <Link to={post.frontmatter.twitter} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary "
                      icon={faTwitter}
                    />
                  </Link>
                  <Link to={post.frontmatter.linkedin} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="mx-8 lg:mx-0 lg:my-5-1 font-awesome text-2xl text-term-primary "
                      icon={faLinkedinIn}
                    />
                  </Link>
                  <Link to={post.frontmatter.facebook} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary"
                      icon={faFacebookF}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* more articles section */}
      {morepost.length &&
        <section className="pb-50 lg:pb-1-04">
          <MoreArticles articles={morepost} />
        </section>
      }

      {/* stay in loop section */}
      <StayLoop />

    </Layout>

  )
}

export default BlogSingle

export const BlogPageQuery = graphql`
  query BlogPage4($id: String!) {
    blog: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        blog_category
        templateKey
        facebook
        linkedin
        twitter
        date(formatString: "DD MMM, YYYY")
       
      }
    }
    moreArticles: allMarkdownRemark(
      filter: { 
              id: { ne: $id },
              frontmatter: { templateKey: { eq: "single-blog" } }
            }
      ) {
       edges {
         node {
           id
           fields {
             slug
           }
           frontmatter {
             title
             blog_category
             templateKey
             featured_image {              
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
             date(formatString: "DD MMM, YYYY")
           }
         }
       }
     }
     awardpagedata: allMarkdownRemark(filter: {id: {eq: $id}}) {
      edges {
        node {
          frontmatter {
            Hreflang {
              hreflang_1
              hreflang_3
              hreflang_2
              hreflang_4
            }
            Schema {
              aggregate_rating_schema
              breadcrumb_schema
              local_business_schema
              logo_schema
              organization_schema
              site_navigation_schema
              sitelinks_schema
              speakable_specification_schema
            }
            seo_metadata {
              facebook_url
              feature_image_height
              image_format
              img_width
              page_last_modified_time
              page_publish_date
              seo_description
              seo_keyword
              seo_title
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              twitter_image_alt_tag
              twitter_page_username
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
          }
        }
      }
    }
  }`